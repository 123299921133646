import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutSidebarLargeComponent } from './admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { HeaderSidebarLargeComponent } from './admin-layout-sidebar-large/header-sidebar-large/header-sidebar-large.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { SearchModule } from '../search/search.module';
import { SidebarLargeComponent } from './admin-layout-sidebar-large/sidebar-large/sidebar-large.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FooterComponent } from '../footer/footer.component';
import { CustomizerComponent } from '../customizer/customizer.component';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { FormsModule } from '@angular/forms';
import { AppFrontLayoutComponent } from './app-front-layout/app-front-layout.component';
import { FrontHeaderComponent } from './app-front-layout/front-header/front-header.component';
import { FrontFooterComponent } from './app-front-layout/front-footer/front-footer.component';
import { SettingService } from '../../services/my-services/settings.service';
import {ProfileService} from '../../services/my-services/profile.service';

const components = [
    HeaderSidebarLargeComponent,
    SidebarLargeComponent,
    FooterComponent,
    CustomizerComponent,
    AdminLayoutSidebarLargeComponent,
    AuthLayoutComponent,
    BlankLayoutComponent,
    AppFrontLayoutComponent,
    FrontHeaderComponent,
    FrontFooterComponent
];

@NgModule({
    imports: [
        NgbModule,
        RouterModule,
        FormsModule,
        SearchModule,
        SharedPipesModule,
        SharedDirectivesModule,
        PerfectScrollbarModule,
        CommonModule
    ],
  declarations: components,
  exports: components, providers: [SettingService, ProfileService]
})
export class LayoutsModule { }
