<div class="modal-header justify-content-center">
    <h4 class="modal-title ">Permission Request Notice</h4>
    <!-- <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
    <p class="m-0 text-center" [innerHTML]="message"></p>
</div>

<div class="modal-footer  justify-content-center">
    <!-- <button type="button" class="btn btn-rounded btn-outline-secondary" (click)="activeModal.dismiss('cancel')">No</button> -->
    <button type="button" class="btn btn-rounded btn-primary" (click)="activeModal.close({action: 'continue'})">Continue</button>
</div>
