import { Component, OnInit } from '@angular/core';
import {LoadingService} from '../../../services/my-services/loading.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html',
  styleUrls: ['./blank-layout.component.scss']
})
export class BlankLayoutComponent implements OnInit {

  constructor( public loadingService: LoadingService, private meta: Meta, private pageTitle: Title) { }

  ngOnInit() {
    this.pageTitle.setTitle('Manage your Calendar & Schedule with Local Businesses | Qbitt');
    this.meta.updateTag({
      name: 'description',
      content: 'Find credible local businesses & service providers in your area. ' +
          'Skip the receptionist and book appointments with them when it’s convenient for you. '});
  }

}
