<div class="auth-layout-wrap"
    style="background-image: url('assets/images/auth-layout-bg.jpg')">
    <div class="container">
        <div class="auth-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<!--loading spinner-->
<div class="loading-spinner" *ngIf="loadingService.showLoadingSpinner">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<!--loading spinner-->
