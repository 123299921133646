import { Component, OnInit } from '@angular/core';
import {Zoom_CLIENT_ID, Zoom_REDIRECT_URL} from '../../shared/@utils/systemEnums';
import {ActivatedRoute} from '@angular/router';
import {CalendarAppService} from '../../shared/services/my-services/calendar-app.service';
import {LocalStoreService} from '../../shared/services/local-store.service';
import {LoadingService} from '../../shared/services/my-services/loading.service';

@Component({
  selector: 'app-zoom-callback',
  templateUrl: './zoom-callback.component.html',
  styleUrls: ['./zoom-callback.component.scss']
})
export class ZoomCallbackComponent implements OnInit {
  loading = false;
  zoomLoginUrl = 'https://zoom.us/oauth/authorize?response_type=code&client_id=' + Zoom_CLIENT_ID +
      '&redirect_uri=' + Zoom_REDIRECT_URL;
  params: any;

  constructor(private route: ActivatedRoute, private calendarService: CalendarAppService,
              private store: LocalStoreService, private loadingService: LoadingService) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    this.loadingService.showLoadingSpinner = true;

    if (!this.params.code) {
      window.location.href = this.zoomLoginUrl;
      this.loadingService.showLoadingSpinner = false;
    } else {
      this.getAccessToken(this.params.code);
      this.loadingService.showLoadingSpinner = false;
      return false;
    }
  }


  public getAccessToken(code) {
    const body = {
      code: code,
      redirectUri: Zoom_REDIRECT_URL
    };
    this.calendarService.getAccessTokenForZoom(body).subscribe(res => {
      const Response: any = res;
      if (Response.status === 200) {
        window.opener.localStorage.setItem('zoom_access_token', JSON.stringify(Response.data.access_token));
        this.store.setItem('zoom_access_token', Response.data.access_token);
        this.getZoomUserProfile();
      } else {
        window.close();
        window.opener.clearMeetingType();
      }
    }, error => {
      window.close();
      window.opener.clearMeetingType();
    });
  }


  /**
   * function is used to get zoom user object
   */
  getZoomUserProfile() {
    this.calendarService.getZoomProfile().subscribe(res => {
      const Response: any = res;
      if (Response.status === 200) {
        this.store.setItem('zoom_user', Response.data);
        window.opener.localStorage.setItem('zoom_user', JSON.stringify(Response.data));
        window.close();
      } else {
        window.close();
        window.opener.clearMeetingType();
      }
    }, error => {
      window.close();
      window.opener.clearMeetingType();
    });
  }

}
