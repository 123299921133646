import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Pattern} from '../../@utils/systemEnums';
import {FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {
  private regx = new RegExp(Pattern.Password);
  private regex1 = new RegExp(Pattern.Mobile);

  constructor(private toaster: ToastrService) { }

  validatePassword(password) {
    if (!password.match(this.regx) && password !== '' && password !== undefined) {
        this.toaster.error('Password should be a minimum of 6 characters, at least one uppercase letter and one number.', 'Password Error', {progressBar: true});
        return;
    }
  }
  validatePhoneNo(phoneNumber) {
    if (!this.regex1.test(phoneNumber) && phoneNumber !== '' && phoneNumber !== undefined) {
      this.toaster.error('Phone No. length between 10 to 12 number.', 'PhoneNo. Error', {progressBar: true});
      return;
    }
  }

  removeHypens(phoneNumber) {
    const regx = /[-]/;
    if (regx.test(phoneNumber)) {
      return phoneNumber.replace(/-/g, '');
    }
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }
}
