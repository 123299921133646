<div class="modal-header">
    <h4 class="modal-title">Qbitt</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p class="m-0">{{data.message}}</p>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-rounded btn-outline-secondary" (click)="activeModal.dismiss('cancel')">No</button>
    <button type="button" class="btn btn-rounded btn-danger" (click)="activeModal.close({action: 'delete'})">Yes</button>
</div>
