<header #header class="front-header" [ngClass]="{'inner-header': router.url !== '/'}">
    <div class="container">
        <nav class="navbar navbar-expand-lg p-0">
            <a class="navbar-brand py-2" routerLink="/">
                <img src="assets/images/logo-with-name-blue.png" alt=""  class="height-45"/>
            </a>
            <button class="navbar-toggler" type="button" (click)="toggleCollapse()">
                &#9776;
            </button>
            <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" routerLink="/">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="http://company.qbitt.io/">For Business</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://company.qbitt.io/about-us">About Us</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://company.qbitt.io/contact-us/">Contact Us</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="https://company.qbitt.io/blog">News</a>
                    </li>
                </ul>

                <ul class="navbar-right navbar-nav ml-auto">
                    <li class="nav-item py-3 py-lg-0">
                        <a class="nav-link d-inline-block mr-3" routerLink="/login">Log In</a>
                        <button routerLink="/signup" class="btn">Sign Up</button>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
