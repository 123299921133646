import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CustomDateFormatterService } from './shared/services/my-services/custom-date-formatter.service';
import { environment } from '../environments/environment';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// import {NgxTypedJsModule} from 'ngx-typed-js';
// import { QuillModule } from 'ngx-quill';

import { SocialLoginModule, AuthServiceConfig, LoginOpt } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import {
  GoogleClientID,
  FacebookAppID,
  Ms_Client_ID,
  MS_EndPoint,
  App_Scopes,
  MS_Redirect_Uri,
  MS_Auth_Url
} from './shared/@utils/systemEnums';
import { ZoomCallbackComponent } from './views/zoom-callback/zoom-callback.component';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { ZoomverifyComponent } from './views/zoomverify/zoomverify.component';

const cookieConfig: NgcCookieConsentConfig = {
  'cookie': {
    'domain': (environment.production) ? 'beta.qbitt.io' : 'localhost',
    'secure': (environment.production),
  },
  'position': 'bottom-right',
  'theme': 'classic',
  'palette': {
    'popup': {
      'background': '#f7f7f7',
      'text': '#4b5064',
      'link': '#3199f6'
    },
    'button': {
      'background': '#3199f6',
      'text': '#ffffff',
      'border': 'transparent'
    },

  },
  'type': 'info',
  'layout': 'my-custom-layout',
  'layouts': {
    "my-custom-layout": '{{buttons}}'
  },
  'elements': {
    'buttons': `

    <div class="row ">
      <div class="col-md-2"></div>
      <div class="text-center col-md-8"><h5 style="color:#525252">Manage Cookie Consent </h5></div>
      <div class="col-md-2 text-right"><span aria-label="dismiss cookie message" tabindex="0" class="cc-close">{{close}}</span>      
      <a aria-label="dismiss cookie message" tabindex="0" style="background-color: #fff; color:#111; " class="cc-btn cc-dismiss cookie">  </a> </div>
      
    </div>

    <span class="cookie-msg pt-2 px-2">{{message}}</span> 
    <div id="view-preference">
    <div class="row pt-3 pl-4 pr-3 d-flex justify-content-between">
      <h5 class="pt-2">Functional</h5>
      <span class="right" >
      <label class="functional">Always active</label>
      <i id="functional" class="icon-angle-down font-22 fw-bold arrow position-relative " ></i> </span>
      
    </div>

    <div id="function-sec" style="font-size:12px; color:#222222;line-height:20px; padding-left:10px;display:none; transition: all 10s ease 0s;">
    The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service explicitly requested by the subscriber or user,
    or for the sole purpose of carrying out the transmission of a communication over an electronic communications network.
    </div>

    <div class="row pt-3 pl-4 pr-3 d-flex justify-content-between">
      <h5>Statistics</h5>
      <span class="statistics">
        <label class="switch">
          <input type="checkbox" checked>
          <span class="slider round"></span>
        </label>     
        &nbsp;&nbsp;<i id="statistics" class="icon-angle-down font-22 fw-bold  statistic-arrow "></i> 
      </span>     
    </div>

    <div id="statistics-sec" style="font-size:12px; color:#222222;line-height:20px; padding-left:10px;display:none; transition: all 10s ease 0s;">
    The technical storage or access that is used exclusively for statistical purposes.
    </div>

    <div class="row pt-3 pl-4 pr-3 d-flex justify-content-between">
      <h5>Marketing</h5>
      <span class="marketing">
        <label class="switch">
          <input type="checkbox" checked>
          <span class="slider round"></span>
        </label>
        &nbsp;&nbsp;<i class="icon-angle-down font-22 fw-bold marketing "></i>
        
      </span>   
    </div>

    <div id="marketing-sec" style="font-size:12px; color:#222222;line-height:20px; padding-left:10px;display:none; transition: all 10s ease 0s;">The technical storage or access is required to create user profiles to send advertising,
    or to track the user on a website or across several websites for similar marketing purposes.</div>

    </div>
      <span id="cookieconsent:desc" class="cc-message pt-3">      
      <a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow cookie">{{allow}}</a>
      <button class="btn-cookie cc-btn cc-dismiss" style="background-color:#fff;color:#505050;font-weight:normal;font-size:15px" >{{deny}}</button>
      <button type="button" id="changetext" class="btn-cookie viewPref" >View preference</button>
      <button type="button" id="save-pref" aria-label="dismiss cookie message" tabindex="0" class="cc-close btn-cookie save-pref" style="display:none">{{close2}}</button>
    </span>

    <span class="btn-cookie-policy pt-2">
      <a class="btn-Policy" href="https://company.qbitt.io/cookie-policy-eu/">Cookie Policy</a>&nbsp;&nbsp;
      <a class="btn-Policy" href="https://company.qbitt.io/privacy-policy/">Privacy Statement</a>
    </span>

    `},
  'content': {
    'message': 'To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Not consenting or withdrawing consent, may adversely affect certain features and functions.',
    'dismiss': 'Accept',
    'deny': 'Deny',
    'link': 'Learn more',
    'href': 'https://cookiesandyou.com',
    'policy': 'Cookie Policy',
    'allow':  'Accept',
    'close': 'X',
    'close2' :'Save preference'
  },
  

  // 'type': 'info',
  // 'content': {
  //   'message': 'To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behavior or unique IDs on this site. Not consenting or withdrawing consent, may adversely affect certain features and functions.',
  //   'dismiss': 'Got it!',
  //   'deny': 'Refuse cookies',
  //   'link': 'Learn more',
  //   'href': 'https://cookiesandyou.com',
  //   'policy': 'Cookie Policy'
  // },

};

const signInOptions: LoginOpt = {
  // auth_type: 'reauthenticate',
  prompt: 'select_account',
};

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(GoogleClientID, signInOptions)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(FacebookAppID)
  }
]);

export function provideConfig() {
  return config;
}



@NgModule({
  declarations: [
    AppComponent, ZoomCallbackComponent, ZoomverifyComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // InMemoryWebApiModule.forRoot(InMemoryDataService, {passThruUnknownUrl: true}),
    AppRoutingModule,
    NgbModule,
    AgmCoreModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    SocialLoginModule,
    MatSlideToggleModule,
    MsalModule.forRoot({
      auth: {
        clientId: Ms_Client_ID, // This is your client ID
        authority: MS_Auth_Url,
        redirectUri: MS_Redirect_Uri,
        validateAuthority: true,
      },
      framework: {
        isAngular: true
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    }, {
      popUp: true,
      consentScopes: App_Scopes.microsoft,
    }),
    NgxLinkifyjsModule.forRoot({
      enableMention: true
    }),
    SharedComponentsModule
    // QuillModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AgmCoreModule,
    { provide: NgbDateParserFormatter, useClass: CustomDateFormatterService },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent,]
})
export class AppModule { }
