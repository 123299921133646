import { Injectable } from '@angular/core';
import {
  CanActivateChild,
  CanActivate,
  CanLoad,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Route,
  CanDeactivate
} from '@angular/router';
import { AuthServiceLocal } from './auth.service';
import { LocalStoreService } from './local-store.service';
import {Observable} from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGaurd implements CanActivate, CanLoad,  CanActivateChild, CanDeactivate<CanComponentDeactivate> {

  constructor(private store: LocalStoreService,
    private router: Router,
    private auth: AuthServiceLocal
  ) { }

  // canActivate() {
  //   if (this.store.getItem('token')) {
  //       this.router.navigate(['/dashboard']);
  //       return true;
  //   }
  //   this.router.navigate(['/login']);
  //   return false;
  // }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   const currentUser = this.auth.getuser();
  //   if (currentUser) {
  //     // authorised so return true
  //     return true;
  //   }
  //
  //   // not logged in so redirect to login page with the return url
  //   this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
  //   return false;
  // }

  // canActivate() {
  //   if (this.store.getItem('token')) {
  //     // this.router.navigate(['/dashboard']);
  //       return true;
  //   } else {
  //     this.router.navigate(['/login']);
  //     return false;
  //   }
  // }
  //
  // canActivateChild() {
  //   if (this.store.getItem('token')) {
  //     // this.router.navigate(['/dashboard']);
  //     return true;
  //   } else {
  //     this.router.navigate(['/login']);
  //     return false;
  //   }
  // }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route) {
    const url: string = route.path;
    // console.log('Url: ' + url);
    return this.checkLogin(url);
  }

  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot) {
    const url: string = state.url;
    // console.log('Url: ' + url);
    return component.canDeactivate ? component.canDeactivate() : true;
  }

  checkLogin(url: string) {
    if (this.auth.isLoggedIn()) {
      return true;
    }
    this.auth.redirectUrl = url;
    this.router.navigate(['/login'], {queryParams: { returnUrl: url }} );

  }
}
