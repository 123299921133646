<div class="modal-header">
    <h4 class="modal-title">Add Business</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="activeModal.close({action: 'cancel'})">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="text-center">
        <img src="assets/images/add-business-img.png" alt="" class="img-fluid">
        <h3 class="mt-3 font-15 font-md-20">Want to register your business?</h3>
    </div>
</div>

<div class="modal-footer justify-content-center">
    <button type="button" class="btn btn-rounded btn-primary px-4" (click)="activeModal.close({action: 'add'})">Add My Business</button>
<!--    <button type="button" class="btn btn-rounded btn-danger px-4" (click)="activeModal.close({action: 'cancel'})">Cancel</button>-->
</div>

