import { Injectable } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import {DataService} from './my-services/data.service';
import {ApiEndPointUrl, userType} from '../@utils/systemEnums';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceLocal {
  authenticated = true;
  _configuration = {
    API_ENDPOINT : ApiEndPointUrl,
  };
  redirectUrl: string;

  constructor(private store: LocalStoreService, private router: Router, private _dataService: DataService) {
    // this.checkAuth();
  }

  checkAddBusinessModelDismiss() {
    return this.store.getItem('add-business-modal-dismiss');
  }

  getuser() {
    return this.store.getItem('user');
  }

  checkPersonalUser() {
    const user = this.getuser();
    const typeOfUser = user.userType;
    if (typeOfUser === userType.Personal) {
        return true;
    } else {
      return false;
    }
  }

  getMyBusiness() {
    return this.store.getItem('business');
  }

  isLoggedIn() {
    return !!this.store.getItem('token');
  }

  getToken() {
    return this.store.getItem('token');
  }

  getGoogleAccessToken() {
    return this.store.getItem('google_auth_access_token');
  }

  getZoomAccessToken() {
    return this.store.getItem('zoom_access_token');
  }

  getNextSyncTokenForGoogleEvents() {
    return this.store.getItem('nextSyncToken');
  }

  getUnreadNotification() {
    return this.store.getItem('unread');
  }

  getGoogleMeetLoggedInUser() {
    return this.store.getItem('google_meet_user');
  }

  getZoomLoggedInUser() {
    return this.store.getItem('zoom_user');
  }

  getMSLoggedInUser() {
    return this.store.getItem('ms_user');
  }


  public adminLogin(body) {
    return this._dataService.postDataWithoutHeader(
        this._configuration.API_ENDPOINT + 'account/login', body
    );
  }
  public checkUsername(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'account/checkusername', body
    );
  }
  public emailExist(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'account/checkemailexist', body
    );
  }
  public adminSignup(body) {
    return this._dataService.postDataWithoutHeader(
        this._configuration.API_ENDPOINT + 'account/signup', body
    );
  }
  public forgotPassword(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'account/forgotpassword', body
    );
  }
  public resetPassword(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'admin/resetpassword', body
    );
  }

  public UpdateProfile(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'admin/updateprofile', body
    );
  }

  public ChangePassword(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'admin/changepassword', body
    );
  }
  public logOut(data) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'profile/logout', data
    );
  }
  public businessDetails() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'business/get'
    );
  }
  public emailVerify(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'account/verifyemail', body
    );
  }
  public resendOtp(body) {
    return this._dataService.postData(
        this._configuration.API_ENDPOINT + 'account/resendotp', body
    );
  }

  public syncUserThroughSocialMedia(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'calander/save-google-access-token-web', body? body:{}
    );
  }

  public syncUserThroughMsal(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'calander/save-google-access-token', body? body:{}
    );
  }

public generateAuthUrl() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'calander/generateAuthUrl'
    );
  }
  

  public updateCopyAppoinmentStatus(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'calander/updateCopyAppoinmentStatus', body? body:{}
    );
  }
}
