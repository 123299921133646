import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

interface DialogData {
  action?: string;
  message?: string;
}

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss']
})
export class DeleteConfirmationModalComponent implements OnInit {
  data: DialogData;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
