import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {AuthServiceLocal} from './auth.service';
import {LocalStoreService} from './local-store.service';
import {loginBy} from '../@utils/systemEnums';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService implements OnInit {
    userType; user;
    public notificationCount: number;
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    menuItems; menuItems$; defaultMenu: IMenuItem[];
    personalMenu: IMenuItem[];
    businessMenu: IMenuItem[];
    employeeMenu: IMenuItem[];
    public selectedItem: IMenuItem;
    settingsSubmenuList = [];
    settings1 = [
        { icon: 'icon-icn_general', name: 'General', state: '/settings/general', type: 'link'},
        { icon: 'icon-icn_invite_friends', name: 'Invite Friends', state: '/settings/invite-friends', type: 'link'},
        // { icon: 'icon-icn_my_referral', name: 'My Referral', state: '/settings/my-referral', type: 'link'},
        { icon: 'icon-icn_add_business', name: 'Add My Business', state: '/settings/add-business', type: 'link'},
        // { icon: 'icon-icn_set_default_screen', name: 'Default Home', state: '/settings/default-home', type: 'link'},
        { icon: 'icon-icn_block_time', name: 'Block Time Settings', state: '/settings/block-time-settings', type: 'link'},
        { icon: 'icon-icn_associate_requests', name: 'Associate Request', state: '/settings/associate-requests', type: 'link'},
        { icon: 'icon-icn_privacy_settings', name: 'Privacy Settings', state: '/settings/privacy-settings', type: 'link'},
        { icon: 'icon-icn_change_password', name: 'Change Password', state: '/settings/change-password', type: 'link'},
        { icon: 'icon-icn_block_list', name: 'Blocked Users', state: '/settings/blocked-users', type: 'link' },
        { icon: 'icon-icn_reminder_preference', name: 'Reminder Preference', state: '/settings/reminder-preference', type: 'link'},
        { icon: 'icon-icn_sync_calendar', name: 'Calender Sync', state: '/settings/calendar-sync', type: 'link'},
        { icon: 'icon-icn_about', name: 'About Qbitt', state: 'https://company.qbitt.io/about-us/', type: 'extLink'},
        { icon: 'icon-icn_terms_condition', name: 'Terms & Conditions', state: 'https://company.qbitt.io/terms/', type: 'extLink'},
        { icon: 'icon-icn_policy', name: 'Privacy Policy', state: 'https://company.qbitt.io/privacy/', type: 'extLink'},
    ];
    settings2 = [
        { icon: 'icon-icn_general', name: 'General', state: '/settings/general', type: 'link'},
        { icon: 'icon-icn_invite_friends', name: 'Invite Friends', state: '/settings/invite-friends', type: 'link'},
        // { icon: 'icon-icn_my_referral', name: 'My Referral', state: '/settings/my-referral', type: 'link'},
        { icon: 'icon-icn_set_business_profile', name: 'My Business', state: '/settings/my-business', type: 'link'},
        // { icon: 'icon-icn_set_default_screen', name: 'Default Home', state: '/settings/default-home', type: 'link'},
        { icon: 'icon-icn_block_time', name: 'Block Time Settings', state: '/settings/block-time-settings', type: 'link'},
        { icon: 'icon-icn_booking_time', name: 'Booking Settings', state: '/settings/booking-settings', type: 'link'},
        { icon: 'icon-icn_associate_requests', name: 'Manage Associates', state: '/settings/manage-associates', type: 'link'},
        { icon: 'icon-icn_reschedule_policy', name: 'Reschedule/Cancel Policy', state: '/settings/reschedule-policy', type: 'link'},
        { icon: 'icon-icn_privacy_settings', name: 'Privacy Settings', state: '/settings/privacy-settings', type: 'link'},
        { icon: 'icon-icn_change_password', name: 'Change Password', state: '/settings/change-password', type: 'link'},
        { icon: 'icon-icn_block_list', name: 'Blocked Users', state: '/settings/blocked-users', type: 'link' },
        { icon: 'icon-icn_reminder_preference', name: 'Reminder Preference', state: '/settings/reminder-preference', type: 'link'},
        { icon: 'icon-icn_sync_calendar', name: 'Calender Sync', state: '/settings/calendar-sync', type: 'link'},
        { icon: 'icon-icn_about', name: 'About Qbitt', state: 'https://company.qbitt.io/about-us/', type: 'extLink'},
        { icon: 'icon-icn_terms_condition', name: 'Terms & Conditions', state: 'https://company.qbitt.io/terms/', type: 'extLink'},
        { icon: 'icon-icn_policy', name: 'Privacy Policy', state: 'https://company.qbitt.io/privacy/', type: 'extLink'},
    ];
    settings3 = [
        { icon: 'icon-icn_general', name: 'General', state: '/settings/general', type: 'link'},
        { icon: 'icon-icn_invite_friends', name: 'Invite Friends', state: '/settings/invite-friends', type: 'link'},
        // { icon: 'icon-icn_my_referral', name: 'My Referral', state: '/settings/my-referral', type: 'link'},
        // { icon: 'icon-icn_set_default_screen', name: 'Default Home', state: '/settings/default-home', type: 'link'},
        { icon: 'icon-icn_block_time', name: 'Block Time Settings', state: '/settings/block-time-settings', type: 'link'},
        { icon: 'icon-icn_booking_time', name: 'Booking Settings', state: '/settings/booking-settings', type: 'link'},
        { icon: 'icon-icn_set_business_profile', name: 'My Workplace', state: '/settings/my-workplace', type: 'link'},
        { icon: 'icon-icn_privacy_settings', name: 'Privacy Settings', state: '/settings/privacy-settings', type: 'link'},
        { icon: 'icon-icn_change_password', name: 'Change Password', state: '/settings/change-password', type: 'link'},
        { icon: 'icon-icn_block_list', name: 'Blocked Users', state: '/settings/blocked-users', type: 'link' },
        { icon: 'icon-icn_reminder_preference', name: 'Reminder Preference', state: '/settings/reminder-preference', type: 'link'},
        { icon: 'icon-icn_sync_calendar', name: 'Calender Sync', state: '/settings/calendar-sync', type: 'link'},
        { icon: 'icon-icn_about', name: 'About Qbitt', state: 'https://company.qbitt.io/about-us/', type: 'extLink'},
        { icon: 'icon-icn_terms_condition', name: 'Terms & Conditions', state: 'https://company.qbitt.io/terms/', type: 'extLink'},
        { icon: 'icon-icn_policy', name: 'Privacy Policy', state: 'https://company.qbitt.io/privacy/', type: 'extLink'},
    ];


    constructor(private auth: AuthServiceLocal, private store: LocalStoreService) {
        this.setMenu();
        this.notificationCount = this.store.getItem('unread');
    }

    ngOnInit () {

    }

    setMenu() {
        this.defaultMenu = [
            {
                name: 'Dashboard',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Home1',
                state: '/dashboard'
            },
            {
                name: 'Calendar',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Calendar-4',
                state: '/calendar'
            },
            {
                name: 'Business',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Management',
                state: '/business'
            },
            // {
            //     name: 'Post',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'link',
            //     icon: 'i-Newspaper',
            //     state: '/post'
            // },
            {
                name: 'Settings',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'dropDown',
                icon: 'i-Gear',
                sub: this.settingsSubmenuList
            },
            // {
            //     name: 'Settings Business',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'dropDown',
            //     icon: 'i-Gear',
            //     sub: [
            //         { icon: 'i-Gear', name: 'General', state: '/settings-personal/general', type: 'link' },
            //         { icon: 'i-Bell', name: 'Notifications', state: '/settings-personal/notifications', type: 'link' },
            //         { icon: 'i-Calendar-4', name: 'Block Time Settings', state: '/settings-personal/block-time-settings', type: 'link' },
            //         { icon: 'i-Suitcase', name: 'My Business', state: '/settings-personal/my-business', type: 'link' },
            //         { icon: 'i-Management', name: 'Manage Associates', state: '/settings-personal/manage-associates', type: 'link' },
            //         { icon: 'i-Repeat-3', name: 'Reschedule/Cancel Policy', state: '/settings-personal/reschedule', type: 'link' },
            //         { icon: 'i-Files', name: 'Privacy Settings', state: '/settings-personal/privacy-settings', type: 'link' },
            //         { icon: 'i-Lock-User', name: 'Change Password', state: '/settings-personal/change-password', type: 'link' },
            //         { icon: 'i-Clock-Back', name: 'Reminder Preference', state: '/settings-personal/reminder-preference', type: 'link' },
            //         { icon: 'i-Files', name: 'About', state: 'https://company.qbitt.io/about/', type: 'extLink' },
            //         { icon: 'i-Files', name: 'Terms & Conditions', state: 'https://company.qbitt.io/terms/', type: 'extLink' },
            //         { icon: 'i-Files', name: 'Privacy Policy', state: 'https://company.qbitt.io/privacy/', type: 'extLink' },
            //     ]
            // },
            // {
            //     name: 'UI kits',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing.',
            //     type: 'dropDown',
            //     icon: 'i-Library',
            //     sub: [
            //         { icon: 'i-Bell', name: 'Alerts', state: '/uikits/alerts', type: 'link' },
            //         { icon: 'i-Split-Horizontal-2-Window', name: 'Accordions', state: '/uikits/accordions', type: 'link' },
            //         { icon: 'i-Medal-2', name: 'Badges', state: '/uikits/badges', type: 'link' },
            //         {
            //             icon: 'i-Arrow-Right-in-Circle',
            //             name: 'Buttons',
            //             type: 'dropDown',
            //             sub: [
            //                 { name: 'Bootstrap Buttons', state: '/uikits/buttons', type: 'link' },
            //                 { name: 'Loding Buttons', state: '/uikits/buttons-loading', type: 'link' }
            //             ]
            //         },
            //         { icon: 'i-ID-Card', name: 'Cards', state: '/uikits/cards', type: 'link' },
            //         { icon: 'i-Line-Chart-2', name: 'Cards metrics', state: '/uikits/cards-metrics', type: 'link' },
            //         { icon: 'i-Credit-Card', name: 'Cards widget', state: '/uikits/cards-widget', type: 'link' },
            //         { icon: 'i-Full-Cart', name: 'Cards ecommerce', state: '/uikits/cards-ecommerce', type: 'link' },
            //         { icon: 'i-Duplicate-Window', name: 'Modals', state: '/uikits/modals', type: 'link' },
            //         { icon: 'i-Speach-Bubble-3', name: 'Popover', state: '/uikits/popover', type: 'link' },
            //         { icon: 'i-Like', name: 'Rating', state: '/uikits/rating', type: 'link' },
            //         { icon: 'i-Loading-3', name: 'Loaders', state: '/uikits/loaders', type: 'link' },
            //     ]
            // },
            // {
            //     name: 'Apps',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'dropDown',
            //     icon: 'i-Computer-Secure',
            //     sub: [
            //         { icon: 'i-Add-File', name: 'Invoice Builder', state: '/invoice', type: 'link' },
            //         { icon: 'i-Email', name: 'Inbox', state: '/inbox', type: 'link' },
            //         { icon: 'i-Speach-Bubble-3', name: 'Chat', state: '/chat', type: 'link' },
            //         { icon: 'i-Calendar', name: 'Calendar', state: '/calendar', type: 'link' },
            //     ]
            // },
            // {
            //     name: 'Forms',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'dropDown',
            //     icon: 'i-File-Clipboard-File--Text',
            //     sub: [
            //         { icon: 'i-File-Clipboard-Text--Image', name: 'Basic components', state: '/forms/basic', type: 'link' },
            //         { icon: 'i-Split-Vertical', name: 'Form layouts', state: '/forms/layouts', type: 'link' },
            //         { icon: 'i-Receipt-4', name: 'Input Group', state: '/forms/input-group', type: 'link' },
            //         { icon: 'i-File-Edit', name: 'Input Mask', state: '/forms/input-mask', type: 'link' },
            //         { icon: 'i-Tag-2', name: 'Tag Input', state: '/forms/tag-input', type: 'link' },
            //         { icon: 'i-Width-Window', name: 'Wizard', state: '/forms/wizard', type: 'link' },
            //         { icon: 'i-Crop-2', name: 'Image Cropper', state: '/forms/img-cropper', type: 'link' },
            //     ]
            // },
            // {
            //     name: 'Data Tables',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'dropDown',
            //     icon: 'i-File-Horizontal-Text',
            //     sub: [
            //         { icon: 'i-File-Horizontal-Text', name: 'List', state: '/tables/list', type: 'link' },
            //         { icon: 'i-Full-View-Window', name: 'Fullscreen', state: '/tables/full', type: 'link' },
            //         { icon: 'i-Code-Window', name: 'Paging', state: '/tables/paging', type: 'link' },
            //         { icon: 'i-Filter-2', name: 'Filter', state: '/tables/filter', type: 'link' },
            //     ]
            // },
            // {
            //     name: 'Sessions',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'dropDown',
            //     icon: 'i-Administrator',
            //     sub: [
            //         { icon: 'i-Add-User', name: 'Sign up', state: '/signup', type: 'link' },
            //         { icon: 'i-Checked-User', name: 'Sign in', state: '/login', type: 'link' },
            //         { icon: 'i-Find-User', name: 'Forgot', state: '/forgot', type: 'link' }
            //     ]
            // },
            // {
            //     name: 'Pages',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'dropDown',
            //     icon: 'i-Windows-2',
            //     sub: [
            //         { icon: 'i-Male', name: 'User Profile', state: '/profile', type: 'link' }
            //     ]
            // },
            // {
            //     name: 'Icons',
            //     description: '600+ premium icons',
            //     type: 'link',
            //     icon: 'i-Cloud-Sun',
            //     state: '/icons/iconsmind'
            // },
            // {
            //     name: 'Others',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'dropDown',
            //     icon: 'i-Double-Tap',
            //     sub: [
            //         { icon: 'i-Error-404-Window', name: 'Not found', state: '/others/404', type: 'link' }
            //     ]
            // },
            // {
            //     name: 'Doc',
            //     type: 'extLink',
            //     tooltip: 'Documentation',
            //     icon: 'i-Safe-Box1',
            //     state: 'http://demos.ui-lib.com/gull-doc'
            // }
        ];

        this.personalMenu = [
            {
                name: 'Dashboard',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Home1',
                state: '/dashboard'
            },
            {
                name: 'Calendar',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Calendar-4',
                state: '/calendar'
            },
            {
                name: 'Business',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Management',
                state: '/business'
            },
            // {
            //     name: 'Post',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'link',
            //     icon: 'i-Newspaper',
            //     state: '/post'
            // },
            {
                name: 'Settings',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'dropDown',
                icon: 'i-Gear',
                sub: this.settings1
            }
        ];

        this.businessMenu = [
            {
                name: 'Dashboard',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Home1',
                state: '/dashboard'
            },
            {
                name: 'Calendar',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Calendar-4',
                state: '/calendar'
            },
            {
                name: 'Business',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Management',
                state: '/business'
            },
            // {
            //     name: 'Post',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'link',
            //     icon: 'i-Newspaper',
            //     state: '/post'
            // },
            {
                name: 'Settings',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'dropDown',
                icon: 'i-Gear',
                sub: this.settings2
            }
        ];

        this.employeeMenu = [
            {
                name: 'Dashboard',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Home1',
                state: '/dashboard'
            },
            {
                name: 'Calendar',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Calendar-4',
                state: '/calendar'
            },
            {
                name: 'Business',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'link',
                icon: 'i-Management',
                state: '/business'
            },
            // {
            //     name: 'Post',
            //     description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
            //     type: 'link',
            //     icon: 'i-Newspaper',
            //     state: '/post'
            // },
            {
                name: 'Settings',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.',
                type: 'dropDown',
                icon: 'i-Gear',
                sub: this.settings3
            }
        ];
        // sets iconMenu as default;
        this.menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
        // navigation component has subscribed to this Observable
        this.menuItems$ = this.menuItems.asObservable();
    }


    // You can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(type) {
        switch (type) {
            case 1:
                this.menuItems.next(this.personalMenu);
                break;
            case 2:
                this.menuItems.next(this.businessMenu);
                break;
            case 3:
                this.menuItems.next(this.employeeMenu);
                break;
            default:
                this.menuItems.next(this.defaultMenu);
        }
    }

}