import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-footer',
  templateUrl: './front-footer.component.html',
  styleUrls: ['./front-footer.component.scss']
})
export class FrontFooterComponent implements OnInit {
  year;
  constructor() { }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }

}
