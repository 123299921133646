import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface DialogData {
  action?: string;
  message?: string;
}
@Component({
  selector: 'app-sync-permission-modal',
  templateUrl: './sync-permission-modal.component.html',
  styleUrls: ['./sync-permission-modal.component.scss']
})
export class SyncPermissionModalComponent implements OnInit {

  data: DialogData;

  message =`This app complies with the <a target="_blank" href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the Limited Use requirements. By proceeding, you are granting the app permission to access your Google Calendar and other Google services.`

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
}
