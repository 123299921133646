<footer class="bg-dark font-13 py-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="text">
                    <p class="text-white mb-1">Copyright © {{year}} Qbitt Inc., All rights reserved</p>
<!--                    <div>-->
<!--                        <a href="https://company.qbitt.io/privacy/" target="_blank" class="text-link mr-3">Privacy Policy</a>-->
<!--                        <a href="https://company.qbitt.io/terms/" target="_blank" class="text-link ">Terms of use</a>-->
<!--                    </div>-->
                    <p class="text-white mt-1 mb-2">
                        934 6th St South, Suite 201 <br>
                        Kirkland, WA 98033 <br>
                        <a href="tel:4256299200">(425) 629-9200</a>
                    </p>
                    <p class="text-white m-0">
                        <span class="mr-2">Built by:</span>
                        <a href="https://www.casoftseattle.com/" target="_blank">CASoft Seattle</a>
                        <span class="px-1">&</span>
                        <a href="https://symphysismarketing.com/" target="_blank">Symphysis Marketing</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mt-4 mt-md-0">
                <div class="row">
                    <div class="col-6">
                        <div class="footer-links">
                            <h5 class="link-title">Need Help?</h5>
                            <ul>
                                <li>
                                    <a href="https://company.qbitt.io/about-us/">About Us</a>
                                </li>
                                <li>
                                    <a href="https://company.qbitt.io/contact-us/">Contact Us</a>
                                </li>
                                <li>
                                    <a href="https://company.qbitt.io/knowledge-base/">FAQ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="footer-links">
                            <h5 class="link-title">Additional Information</h5>
                            <ul>
                                <li>
                                    <a href="https://company.qbitt.io/scheduling-app-for-startups/">Start-ups</a>
                                </li>
                                <li>
                                    <a href="https://company.qbitt.io/about-us/#section-182-35">Investor Opportunities</a>
                                </li>
                                <li>
                                    <a href="https://company.qbitt.io/privacy/">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="https://company.qbitt.io/terms/">Terms of Use</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 mt-3 mt-lg-0">
                <ul class="d-flex align-items-center justify-content-center mb-2 font-20">
                    <li>
                        <a href="https://twitter.com/QbittInc" target="_blank" class="text-white mx-2 px-2"><i class="icon-twitter1"></i></a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/qbittinc" target="_blank" class="text-white mx-2 px-2"><i class="icon-facebook1"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/qbittinc" target="_blank" class="text-white mx-2 px-2"><i class="icon-instagram1"></i></a>
                    </li>
                    <li>
                        <a href="https://in.linkedin.com/company/qbittinc" target="_blank" class="text-white mx-2 px-2"><i class="icon-linkedin1"></i></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCXOnCbqsKXUH1J0zu4i0H8w/featured" target="_blank" class="text-white mx-2 px-2"><i class="icon-youtube1"></i></a>
                    </li>
                </ul>

                <div class="d-flex align-items-center justify-content-center justify-content-md-end mx-auto" style="max-width: 300px;">
                    <a href="https://apps.apple.com/app/id1455220101" target="_blank" class="p-1">
                        <img src="assets/images/btn_appstore.png" alt="app-store btn" class="img-fluid">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.qbitt.android" target="_blank" class="p-1">
                        <img src="assets/images/btn_playstore.png" alt="play-store btn" class="img-fluid">
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
