import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarAppEvent } from 'src/app/shared/models/calendar-event.model';
import {ApiEndPointUrl, MS_EndPoint, ZoomApiEndPointUrl} from '../../@utils/systemEnums';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarAppService {
  public events: CalendarAppEvent[];
  _configuration = {
    API_ENDPOINT : ApiEndPointUrl,
    ZOOM_API_ENDPOINT: ZoomApiEndPointUrl,
    GOOGLE_API_ENDPOINTS: 'https://www.googleapis.com/calendar/v3/calendars/'
  };

  constructor(
    private http: HttpClient,
    private _dataService: DataService
  ) {}

  public getAppointments() {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/getall/0'
    );
  }
  public getFilteredAppointmentsByDate(data) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/getall-web',  data
    );
  }
  public getAllPendingAppointments(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/pending/getall' , body
    );
  }
  public acceptRejectAppointment(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/acceptappointment' , body
    );
  }
  public getTimeSlots(data) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/gettimeslots', data
    );
  }
  public addAppointments(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + '/api/v1/event/sync', body
    );
  }
  public bookAppointment(body) {
    console.log(body);
    
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/add', body
    );
  }
  public getAppointmentById(id) {
    return this._dataService.getDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/getbyid/' + id
    );

  }
  public editAppointment(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/update', body
    );
  }
  public rescheduleAppointment(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/reschedule', body
    );
  }
  public cancelAppointment(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/delete', body
    );
  }
  public syncAppointments(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/sync', body
    );
  }
  public checkDateAvailability(body) {
    return this._dataService.postDataWithHeader(
        this._configuration.API_ENDPOINT + 'appointment/checkavailability', body
    );
  }
  public addEventInGoogleCalendar(calendarId , body, params) {
    return this._dataService.postDataWithHeaderAndQueryParams(
        this._configuration.GOOGLE_API_ENDPOINTS + calendarId + '/events', body, params
    );
  }
  public getGoogleEvents(calendarId, params) {
    return this._dataService.getDataWithHeaderForGoogleEvents(
        this._configuration.GOOGLE_API_ENDPOINTS + calendarId + '/events', params
    );
  }
  public getAccessTokenForZoom(body) {
    return this._dataService.postDataWithHeaderForZoomToken(
        this._configuration.ZOOM_API_ENDPOINT + 'zoom/get-token' , body
    );
  }
  public getZoomProfile() {
    return this._dataService.getDataWithHeaderForZoomUser(
        this._configuration.ZOOM_API_ENDPOINT + 'zoom/get-user'
    );
  }
  public logoutZoomUser() {
    return this._dataService.deleteDataWithHeaderForZoomUser(
        'https://api.zoom.us/v2/users/me/token'
    );
  }
  public createZoomMeeting(body) {
    return this._dataService.postDataWithHeaderForZoomMeeting(
        this._configuration.ZOOM_API_ENDPOINT + 'zoom/create-meeting' , body
    );
  }
  public createMicrosoftMeeting(body) {
    return this._dataService.postDataWithHeaderForMicrosoftMeeting(
        MS_EndPoint + 'onlineMeetings' , body
    );
  }
  public getMicrosoftEvents() {
    return this._dataService.getDataWithHeaderForMicrosoftEvents(
        MS_EndPoint + 'calendar/events'
    );
  }
}
