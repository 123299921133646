import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'distancePipe'
})
export class DistanceConvertPipe implements PipeTransform {
  transform(distance) {
    const miles = distance / 1.6;
    return miles;
  }

}
