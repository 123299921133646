import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  constructor(private sanitizer: DomSanitizer) { }

  transform(url) {
    const match = url.match(this.regExp);
    if (match && match[2].length === 11) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + match[2] + '/?enablejsapi=1&version=3&playerapiid=ytplayer');
      return url;
    } else {
      return 'error';
    }
  }

}
