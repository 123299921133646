import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { FeatherIconComponent } from './feather-icon/feather-icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SearchModule } from './search/search.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutsModule } from './layouts/layouts.module';
import { SidebarRightComponent } from './sidebar-right/sidebar-right.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import {MatchPasswordDirective} from '../directives/match-password.directive';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { AddBusinessModalComponent } from './add-business-modal/add-business-modal.component';
import { SyncPermissionModalComponent } from './sync-permission-modal/sync-permission-modal.component';
import { AddUpdateSocialFieldComponent } from './add-update-social-field/add-update-social-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

const components = [
  BtnLoadingComponent,
  FeatherIconComponent,
  SidebarRightComponent,
  LoadingSpinnerComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    SharedPipesModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    SearchModule,
    PerfectScrollbarModule,
    NgbModule
  ],
    declarations: [
        components,
        MatchPasswordDirective,
        DeleteConfirmationModalComponent,
        ConfirmationModalComponent,
        AddBusinessModalComponent,
        SyncPermissionModalComponent,
        AddUpdateSocialFieldComponent
    ],
    exports: [
        components,
        MatchPasswordDirective
    ]
})
export class SharedComponentsModule { }
