import {AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import {Router} from '@angular/router';
import {AuthServiceLocal} from '../../../services/auth.service';
import {LoadingService} from '../../../services/my-services/loading.service';
import {Meta, Title} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddBusinessModalComponent} from '../../add-business-modal/add-business-modal.component';
import {LocalStoreService} from '../../../services/local-store.service';

@Component({
  selector: 'app-admin-layout-sidebar-large',
  templateUrl: './admin-layout-sidebar-large.component.html',
  styleUrls: ['./admin-layout-sidebar-large.component.scss']
})
export class AdminLayoutSidebarLargeComponent implements OnInit, AfterContentChecked {

    @ViewChild(PerfectScrollbarDirective, { static: true }) perfectScrollbar: PerfectScrollbarDirective;

    constructor(
      public navService: NavigationService, private auth: AuthServiceLocal,
      public searchService: SearchService, private router: Router, public loadingService: LoadingService,
      private meta: Meta, private pageTitle: Title, private modalService: NgbModal,
      private store: LocalStoreService, private cd: ChangeDetectorRef
    ) {
        this.checkFirstUrl();
        this.checkSecondUrl();

    }

    ngOnInit() {
        this.pageTitle.setTitle('Manage your Calendar & Schedule with Local Businesses | Qbitt');
        this.meta.updateTag({
            name: 'description',
            content: 'Find credible local businesses & service providers in your area. ' +
                'Skip the receptionist and book appointments with them when it’s convenient for you. '});

        // this.openAddBusinessModel();
        this.checkModalDismiss();
    }

    ngAfterContentChecked() {
        this.cd.detectChanges();
    }

    checkFirstUrl() {
        if (this.router.url === '/settings/add-business') {
            if (this.auth.checkPersonalUser()) {
                return true;
            } else {
                this.router.navigateByUrl('/settings/my-business');
                return false;
            }
        }
    }
    checkSecondUrl() {
        if (this.router.url === '/settings/my-business') {
            if (!this.auth.checkPersonalUser()) {
                return true;
            } else {
                this.router.navigateByUrl('/settings/add-business');
                return false;
            }
        }
    }


    /**
     * function used to check for show add business modal
     */
    checkModalDismiss() {
        if (!this.auth.checkAddBusinessModelDismiss() && this.auth.checkPersonalUser()) {
            this.openAddBusinessModel();
        }
    }

    /**
     * function used to open add business modal
     */
    openAddBusinessModel() {
        const dialogRef = this.modalService.open(AddBusinessModalComponent, {centered: true, backdrop: 'static'});
        dialogRef.result.then((result) => {
            if (!result) {
                return;
            }
            const dialogAction = result.action;
            if (dialogAction === 'add') {
                this.router.navigate(['/settings/add-business']);
                this.store.setItem('add-business-modal-dismiss', true);
            } else if (dialogAction === 'cancel') {
                // this.router.navigate(['/add-business']);
                this.modalService.dismissAll();
                this.store.setItem('add-business-modal-dismiss', true);
            }
        });
    }
}
