import { Pipe, ChangeDetectorRef } from '@angular/core';
import { parse, formatDistanceToNow } from 'date-fns';
import { AsyncPipe } from '@angular/common';
import { timer, Observable } from 'rxjs';
import { tap, map, distinctUntilChanged, startWith } from 'rxjs/operators';
import * as moment from 'moment';

@Pipe({
    name: 'timeAgo',
    pure: false,
})
export class TimeAgoPipe extends AsyncPipe {

    // private time: Date;
    // private formatted$: Observable<string>;
    //
    // constructor(private cd: ChangeDetectorRef) {
    //     super(cd);
    //
    //     this.formatted$ = timer(0, 1000).pipe(
    //         map(() => formatDistanceToNow(this.time, { addSuffix: true, includeSeconds: true })),
    //         distinctUntilChanged()
    //     );
    // }

    public transform(TimeStamp: any): any {
        // this.time = value;
        // return super.transform(this.formatted$);

        const dt = new Date(TimeStamp).getDate();
        const month = new Date(TimeStamp).getMonth();
        const year = new Date(TimeStamp).getFullYear();
        const currentDate = moment(new Date());
        const receivedDate = moment(new Date(TimeStamp));
        const dateDiff = currentDate.diff(receivedDate, 'days');

        if (dateDiff > 7) {
            const timeInString = moment(receivedDate).format('MMMM Do YYYY');
            return timeInString;
        } else {
            const timeFromNow = moment(TimeStamp).fromNow();
            // console.log('timeFromNow :', timeFromNow);
            // console.log('timeFromNow2 :',  );
            return timeFromNow;
        }
    }

}
