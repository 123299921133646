<div class="modal-header">
    <h4 class="modal-title">Custom Link</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="this.form">
        <div class="row">
            <div class="col-md-4 col-12" style="display: flex;justify-content: center;margin: 0 auto">
                <div onclick="document.getElementById('getFile').click()" style="position: relative;">
                    <img class="profile-picture shadow mb-2 h-100 w-100" style="border-radius: 16px; max-height: 108px; max-width: 108px; min-height : 108px; min-width: 108px; object-fit: cover;" [src]="this.form.get('profileImageUrl').value || 'assets/images/placeholder-image.png'" alt=""> 
                    <input name="profile-image" type="file" id="getFile" #pic (change)="readProfileImage($event)" accept="image/*" hidden>    
                    <button *ngIf="this.form.get('profileImageUrl').value" style="position: absolute; top: -10px; right: -10px;" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>Handle Name</label>
                    <input formControlName="name" type="text" class="form-control form-control-rounded">
                    <div *ngIf="this.form.get('name').hasError('required') && this.form.get('name').touched" [ngClass]="'error'">
                        {{errorMessage.handleName.required}}
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>Handle URL</label>
                    <input  formControlName="url" type="text" class="form-control form-control-rounded">
                    <div *ngIf="this.form.get('url').hasError('required') && this.form.get('url').touched" [ngClass]="'error'">
                        {{errorMessage.handleURL.required}}
                    </div>
                    <div *ngIf="checkValidation(this.form.get('url').value) && this.form.get('url').value" [ngClass]="'error'">
                        <span>Invalid value</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer justify-content-center">
    <!-- <button type="button" class="btn btn-rounded btn-danger px-4" (click)="activeModal.close({action: 'cancel'})">Cancel</button> -->
    <button type="button" class="btn btn-rounded btn-primary px-4" [disabled]="this.form.invalid" (click)="activeModal.close(this.form.value)">Save</button>
</div>