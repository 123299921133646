import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import {AppFrontLayoutComponent} from './shared/components/layouts/app-front-layout/app-front-layout.component';
import {ZoomCallbackComponent} from './views/zoom-callback/zoom-callback.component';
import {ZoomverifyComponent} from './views/zoomverify/zoomverify.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'calendar',
        loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },
    {
        path: 'business',
        loadChildren: () => import('./views/business/business.module').then(m => m.BusinessModule)
    },
    {
        path: 'post',
        loadChildren: () => import('./views/post/post.module').then(m => m.PostModule)
    },
    {
        path: 'notification',
        loadChildren: () => import('./views/notification/notification.module').then(m => m.NotificationModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'appointment',
        loadChildren: () => import('./views/appointment/appointment.module').then(m => m.AppointmentModule)
    },
    {
        path: '',
        loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
    }
    // {
    //     path: 'icons',
    //     loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
    // },
  ];

const routes: Routes = [
    {
        path: '',
        component: AppFrontLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./views/front-pages/front-pages.module').then(m => m.FrontPagesModule)
            }
        ]
    },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd], canActivateChild: [AuthGaurd],
    children: adminRoutes
  },
  {   path: 'zoom-callback', component: ZoomCallbackComponent },
  {   path: 'zoomverify/verifyzoom', component: ZoomverifyComponent },
  {
      path: '**',
      redirectTo: '404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
