import { Injectable } from '@angular/core';
import { DataService} from './data.service';
import {ApiEndPointUrl} from '../../@utils/systemEnums';

@Injectable()
export class SettingService {
    _configuration = {
        API_ENDPOINT : ApiEndPointUrl,
    };
    constructor( private _dataService: DataService ) { }

    public changePassword(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/changepassword', data
        );
    }
    public pendingRequest(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'employee/pendingrequest', data
        );
    }
    public processRequest(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'employee/processrequest', data
        );
    }
    public updateSettings(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/updatesettings', data
        );
    }
    public getEmployeeByBusiness(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'employee/getbybusiness', data
        );
    }
    public employeeRemove(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'employee/remove', data
        );
    }
    public getBlockUsers(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'blockuser/getall', data
        );
    }
    public unblockUser(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'blockuser/processrequest', data
        );
    }
    public updateSetting(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/updatesettings', data
        );
    }
    public getAllEmployee(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'employee/getall', data
        );
    }
    public sendEmployeeFriendRequest(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'employee/sendrequest', data
        );
    }
    public getBusinessById(id) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + 'business/getbyid/' + id
        );
    }
    public reschedulecancellation(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'business/updatepolicy', data
        );
    }
    public businessSlugGetter(data) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + 'public/business/getslug/' + data
        );
    }
    public getNotification(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/notifications', data
        );
    }
    public readNotification(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/notifications/read', data
        );
    }
    public deleteNotification(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/notification/delete', data
        );
    }
    public sendFeedback(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'appointment/feedback', data
        );
    }
    public getAppointmentById(id) {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + 'appointment/getbyid/' + id
        );
    }
    public blockTime(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/blocktime/add', data
        );
    }
    public unblockTime(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/blocktime/delete', data
        );
    }
    public getAllContactsFromGoogle() {
        return this._dataService.getDataWithHeaderForGoogleContact(
            'https://www.google.com/m8/feeds/contacts/default/full'
        );
    }
    public findContact(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/findfriends', data
        );
    }
    public inviteContact(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/invitefriends', data
        );
    }
    public getAllReferrals() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/getReferrals'
        );
    }
}
