import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'stringDecoder'
})
export class StringDecoderPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string) {
    const urlRegex = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;
    const newValue = decodeURIComponent(value);
    return newValue;
  }

  // urls: any = /(\b(https?|http|ftp|ftps|Https|rtsp|Rtsp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;
  // hashtags: any = /(^|\s)(#[a-z\d][\w-]*)/ig;
  // mentions: any = /(^|\s)(@[a-z\d][\w-]*)/ig;
  // emails: any = /(\S+@\S+\.\S+)/gim;
  //
  // transform(value: string) {
  //   const newValue = decodeURIComponent(value);
  //   return this.parseUrl(newValue);
  // }
  //
  // private parseUrl(text: string) {
  //   // Find/Replace URL's in text
  //   if (text.match(this.urls)) {
  //     text = text.replace(this.urls, (url) => {
  //       // return url.link(url);
  //       // const anchor = document.createElement('a');
  //       // anchor.setAttribute('href', url);
  //       // anchor.setAttribute('target', '_blank');
  //       return '<a href="' + url + '" target="_blank">' + url + '</a>';
  //       // const content = '<a href="' + url + '" target="_blank">' + url + '</a>';
  //       // return this.sanitizer.bypassSecurityTrustUrl(content);
  //     });
  //   }
  //
  //   // Find/Replace @Handle/Mentions in text
  //   if (text.match(this.hashtags)) {
  //     text = text.replace(this.hashtags, '<a href="/search/hashtag/$2" class="hashtag-link">$1$2</a>');
  //   }
  //
  //   // Find/Replace #hashtags in text
  //   if (text.match(this.mentions)) {
  //     text = text.replace(this.mentions, '<a href="/search/handle/$2" class="handle-link">$1$2</a>');
  //   }
  //
  //   // Find/Replace email addresses in text
  //   if (text.match(this.emails)) {
  //     text = text.replace(this.emails, '<a href="mailto:$1">$1</a>');
  //   }
  //
  //   return text;
  // }


}
