import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {LocalStoreService} from '../../../services/local-store.service';
import {Router} from '@angular/router';
import {LoadingService} from '../../../services/my-services/loading.service';
import {Meta, Title} from '@angular/platform-browser';
@Component({
  selector: 'app-app-front-layout',
  templateUrl: './app-front-layout.component.html',
  styleUrls: ['./app-front-layout.component.scss']
})
export class AppFrontLayoutComponent implements OnInit, AfterViewInit {

  moduleLoading: boolean;

  constructor( private store: LocalStoreService, public router: Router,
               public loadingService: LoadingService, private meta: Meta, private pageTitle: Title,
               private cd: ChangeDetectorRef) {

    this.checkUserLogin();
  }

  ngOnInit() {
    this.pageTitle.setTitle('Register Your Business for Free | Qbitt');
    this.meta.updateTag({
      name: 'description',
      content: 'Add your business to our local business directory for free. ' +
          'Connect with your customers and let them book appointments on their own time.'});

    // this.router.events.subscribe(event => {
    //   if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
    //     this.moduleLoading = true;
    //   }
    //   if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
    //     this.moduleLoading = false;
    //   }
    // });
    // this.checkUserLogin();
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  /**
   * function used to check user login
   */
  checkUserLogin() {
    if (this.store.getItem('token')) {
      this.router.navigate(['dashboard']);
    } else {
      return false;
    }
  }
}
