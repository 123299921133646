import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

interface DialogData {
  action?: string;
  message?: string;
}

@Component({
  selector: 'app-add-business-modal',
  templateUrl: './add-business-modal.component.html',
  styleUrls: ['./add-business-modal.component.scss']
})
export class AddBusinessModalComponent implements OnInit {

  data: DialogData;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
