import { Injectable } from '@angular/core';
import { SidebarDirective } from '../directives/sidebar.directive';
import {LocalStoreService} from './local-store.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarHelperService {

  sidenavInstances: SidebarDirective[];

  constructor(private store: LocalStoreService) {
      this.sidenavInstances = [];
  }

  /**
   * Set sidenav
   *
   * @param id
   * @param instance
   */
  setSidenav(id, instance): void {
      this.sidenavInstances[id] = instance;
  }

  /**
   * Get sidenav
   *
   * @param id
   * @returns {any}
   */
  getSidenav(id): SidebarDirective {
      // console.log(this.sidenavInstances);
      return this.sidenavInstances[id];
  }
}
