import {Injectable, OnInit} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LoadingService implements OnInit {

  public showLoadingSpinner = false;

  constructor() { }

  ngOnInit(): void {

  }
}
