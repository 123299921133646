import { Component, OnInit } from '@angular/core';
import {LoadingService} from '../../../services/my-services/loading.service';
import {Meta, Title} from '@angular/platform-browser';
import {LocalStoreService} from '../../../services/local-store.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  constructor( public loadingService: LoadingService, private meta: Meta,
               private pageTitle: Title, private store: LocalStoreService, public router: Router) {
    this.checkUserLogin();
  }

  ngOnInit() {
    this.pageTitle.setTitle('Manage your Calendar & Schedule with Local Businesses | Qbitt');
    this.meta.updateTag({
      name: 'description',
      content: 'Find credible local businesses & service providers in your area. ' +
          'Skip the receptionist and book appointments with them when it’s convenient for you. '});
  }

  /**
   * function used to check user login
   */
  checkUserLogin() {
    if (this.store.getItem('token')) {
      this.router.navigate(['dashboard']);
    } else {
      return false;
    }
  }

}
