import { Injectable } from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct , NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import {Utils} from '../../utils';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomDateFormatterService  extends  NgbDateParserFormatter  {

  DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    const dateString = Utils.ngbDateToDate(date);
    return date ? moment(dateString).format('ddd, MMM DD YYYY') : '';
    // if (date.month < 10) {
    //   return date ? '0' + date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
    // } else {
    //   return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
    // }
  }

}
