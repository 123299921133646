export const ErrorMessages = {
    websiteURL : {
        required: 'Website URL is required.'
    },
    name: {
        required: 'Name is required.',
    },
    handleName: {
        required: 'Handle Name is required.',
    },
    handleURL: {
        required: 'Handle URL is required.',
    },
    firstName: {
        required: 'First Name is required.',
    },
    lastName: {
        required: 'Last Name is required.',
    },
    business: {
        required: 'Business Name is required.',
    },
    service: {
        required: 'Service is required.',
    },
    serviceName: {
        required: 'Service Name is required.',
    },
    timeDuration: {
        required: 'Time duration is required.',
    },
    bufferDuration: {
        required: 'Buffer duration is required.',
    },
    hours: {
        required: 'Hours is required.',
    },
    minutes: {
        required: ' Minutes is required.',
    },
    businessCategory: {
        required: 'Business Category is required.',
    },
    category: {
        required: 'Category is required.',
    },
    subCategory: {
        required: 'SubCategory is required.',
    },
    serviceAreaRadius: {
        required: 'Service Area Radius is required.',
    },
    userName: {
        required: 'Username is required.',
        available: 'Username already taken by another, Try another. Available:'
    },
    password: {
        required: 'Password is required.',
        pattern: 'Password should be a minimum of 6 characters, at least one uppercase letter and one number.'
    },
    newPassword: {
        required: 'New Password is required.',
        pattern: 'Password should be a minimum of 6 characters, at least one uppercase letter and one number.'
    },
    oldPassword: {
        required: 'Old Password is required.',
        pattern: 'Password should be a minimum of 6 characters, at least one uppercase letter and one number.'
    },
    confirmPassword: {
        required: 'Confirm Password is required.',
        pattern: 'Confirm Password do not match.'
    },
    email : {
        required: 'Email is required.',
        pattern: 'Please enter valid email.'
    },
    phoneNumber : {
        required: 'Phone Number is required.',
        minLength: 'Phone No. min length is 10 digit',
        maxLength: 'Phone No. max length is 12 digit',
        pattern: 'Phone No. is not valid.'
    },
    street: {
        required: 'Street is required.',
    },
    country: {
        required: 'Country is required.',
    },
    city: {
        required: 'City is required.',
    },
    state: {
        required: 'State is required.',
    },
    address: {
        required: 'Address is required.',
    },
    media: {
        required: 'Media is required.',
    },
    recaptcha: {
        required: 'Please check recaptcha.'
    },
    termsCheck: {
        required: 'Please accept terms & conditions.'
    },
    timeSlot: {
        required: 'Select at least one time slot to proceed.'
    },
    noRecodeFound: 'No Record Found',
    noPostAvailable: 'No post available',
    noContactAvailable: 'We could not find any contacts with this email.',
    noBusinessAvailable: 'No business found for given search criteria. Please update search option to find businesses.',
    noUserAvailable: 'No people found for given search criteria.',
    categoryExist: 'This Category is already exist.',
    categoryLimitExceed: 'You can add upto 3 categories.',
    noReferralFound: 'No Referral Found.',
    eventsAlreadyUptoDate: 'Already Upto Date',
    noEventsAvailable: 'No Events are Available at this Moment',
    somethingWentWrong: 'Something Went Wrong',
    loginFailed: 'Login failed',
    getTokenFailed: 'Get Token Failed',
    calendarSyncField: {
        required: 'Please Select Atleast One to Sync Calendar'
    }
};

export const confirmationMessage = {
    appointmentDelete: 'Are you sure you want to delete this Appointment?',
    postDelete: 'Are you sure you want to delete this Post?',
    appointmentDecline: 'Are you sure you want decline this Appointment?',
    leaveWorkPlace: 'Are you sure you want to leave Business?',
    removeAssociate: 'Are you sure you want to Remove Your Associate?',
    microsoftLogin: 'Qbitt will need consent to schedule meeting on your behalf. If you are a admin of your organization,' +
        'your can continue. Otherwise, please contact your administrator for help on providing consent manually.'
};
