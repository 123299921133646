import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss']
})
export class SidebarRightComponent implements OnInit {
  sidebarToggle = false;

  constructor() { }

  ngOnInit(): void {
  }


  public toggleSidebar() {
    this.sidebarToggle = !this.sidebarToggle;
    const body = document.getElementsByTagName('body')[0];
    if (this.sidebarToggle) {
      body.classList.add('o-hidden');
    } else {
      body.classList.remove('o-hidden');
    }
  }

}
