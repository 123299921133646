import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertUrl'
})
export class ConvertUrlPipe implements PipeTransform {
  transform(url) {
    if (url.indexOf('http') > -1 || url.indexOf('https') > -1) {
      return url;
    } else {
      return 'http://' + url;
    }
  }

}
