import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-front-header',
  templateUrl: './front-header.component.html',
  styleUrls: ['./front-header.component.scss']
})
export class FrontHeaderComponent implements OnInit {
  params;
  isMenuCollapsed = true;
  scrollHeight = 0;
  @ViewChild('header') header: ElementRef;
  constructor(private route: ActivatedRoute, public router: Router) {
    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    // window.addEventListener('scroll', this.scroll, true);

    // if (this.router.url !== '/') {
    //   this.header.nativeElement.classList.add();
    // }
  }

  toggleCollapse() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    if (this.scrollHeight > 0) {
      this.header.nativeElement.classList.add('header-white');
    } else {
      this.header.nativeElement.classList.toggle('header-white');
    }
  }

  /**
   * function used to call on window scroll
   */
  scroll = (): void => {
    this.scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // if (this.isMenuCollapsed) {
      if (this.scrollHeight > 0) {
        this.header.nativeElement.classList.add('header-white');
      } else {
        if (this.isMenuCollapsed) {
          this.header.nativeElement.classList.remove('header-white');
        }
      }
    // }
  }

}
