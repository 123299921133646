<div class="page-layout">
    <app-front-header></app-front-header>
    <div class="page-content" [ngClass]="{ 'top-padding':  router.url !== '/'}">
        <router-outlet></router-outlet>
    </div>
    <app-front-footer></app-front-footer>
</div>

<!--loading spinner-->
<!--<div class="loading-spinner" *ngIf="loadingService.showLoadingSpinner">-->
<!--    <div class="spinner-border text-primary" role="status">-->
<!--        <span class="sr-only">Loading...</span>-->
<!--    </div>-->
<!--</div>-->
<!--loading spinner-->
