import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import {ApiEndPointUrl} from '../../@utils/systemEnums';

@Injectable()
export class ProfileService {
    _configuration = {
        API_ENDPOINT : ApiEndPointUrl,
    };

    constructor(
        private _dataService: DataService ) {
    }

    public getProfileDetails() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/get'
        );
    }
    public getPublicUserProfile(body) {
        return this._dataService.postData(
            this._configuration.API_ENDPOINT + 'public/getPublicUserDetails' , body
        );
    }

    public editProfileDetails(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/update', body

        );
    }
    public getDashboardData() {
        return this._dataService.getDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/getdashboard'
        );
    }

    public getMyConnections(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/getmyconnections', body
        );
    }
    public sendConnectionRequest(body) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/sendrequest', body
        );
    }
    public getConnectionList(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/getall', data
        );
    }
    public getPendingConnections(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/pendingrequest', data
        );
    }
    public processConnectionRequest(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/processrequest', data
        );
    }
    public postGetByUser(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'post/getbyuser', data
        );
    }
    public getFavouriteBusiness(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'business/getfavourite', data
        );
    }
    public getConnectionDetails(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/getdetails', data
        );
    }
    public getMembersPost(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'post/getbymember', data
        );
    }
    public getPublicMembersPost(data) {
        return this._dataService.postData(
            this._configuration.API_ENDPOINT + 'public/getUserPosts', data
        );
    }
    public getMembersFavourite(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'business/getmemberfavourite', data
        );
    }
    public getPublicMembersFavourite(data) {
        return this._dataService.postData(
            this._configuration.API_ENDPOINT + 'public/getUserFavouriteBusiness', data
        );
    }

    public getMembersFriends(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/getmemberconnections', data
        );
    }

    public getPublicMembersFriends(data) {
        return this._dataService.postData(
            this._configuration.API_ENDPOINT + 'public/getUserConnections', data
        );
    }

    public getnotificationList(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/notifications', data
        );
    }

    public getAllEmployee(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'employee/getall', data
        );
    }

    public inviteFriends(data) {
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'profile/invitefriends', data
        );
    }

    public getFollowersList(data){
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/getAllFollower', data
        )
    }

    public  getFollowingList(data){
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/getAllFollowing', data
        )
    }
    public followUnfollow(data){
        return this._dataService.postDataWithHeader(
            this._configuration.API_ENDPOINT + 'connection/addFollowUnFollowUser', data
        )
    }
}; 
