import { Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LocalStoreService} from '../local-store.service';
import {Zoom_CLIENT_ID, Zoom_CLIENT_SECRET} from '../../@utils/systemEnums';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private errorData: { errorDesc: string; errorTitle: string };

    constructor(public http: HttpClient, private store: LocalStoreService) {}

    public getData<T>(url: string) {
        return this.http.get(url).pipe(
            catchError(this.handleError)
        );
    }
    public getDataWithHeader<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': this.getToken(),
                'platform':  'Web'
            })
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public postDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': this.getToken(),
                'platform':  'Web',
                'time': new Date().getTime().toString()
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public getDataWithHeaderForGoogleContact<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getGoogleAccessToken(),
                'Content-Type': 'application/json'
            }),
            params: {
                'GData-Version': '3.0',
                'alt': 'json'
            }
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public getDataWithHeaderForGoogleEvents<T>(url: string, params: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getGoogleAccessToken(),
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public getDataWithHeaderForMicrosoftEvents<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getMicrosoftEventsAccessToken(),
                'Content-Type': 'application/json'
            }),
            // params: params
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }


    public postDataWithHeaderAndQueryParams<T>(url: string, toAdd: any, params: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getGoogleAccessToken(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            params: params
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public getDataWithHeaderForZoomUser<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getZoomAccessToken()
            })
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public deleteDataWithHeaderForZoomUser<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getZoomAccessToken()
            })
        };
        return this.http.delete(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public getDataWithHeaderForMicrosoftUser<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getMicrosoftAccessToken(),
                'Content-Type': 'application/json',
            })
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public postDataWithHeaderForZoomToken<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'zoom-token': 'Basic' + ' ' + window.btoa('' + Zoom_CLIENT_ID + ':' + Zoom_CLIENT_SECRET + '')
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public postDataWithHeaderForZoomMeeting<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getZoomAccessToken(),
                'Content-Type': 'application/json',
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public postDataWithHeaderForMicrosoftMeeting<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + this.getMicrosoftAccessToken(),
                'Content-Type': 'application/json',
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }


    public postDataWithoutHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'platform':  'Web'
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postData<T>(url: string, toAdd: any) {
        return this.http.post(url, toAdd).pipe(
            catchError(this.handleError)
        );
    }

    getToken() {
        return this.store.getItem('token');
    }

    getGoogleAccessToken() {
        return this.store.getItem('google_auth_access_token');
    }

    getZoomAccessToken() {
        return this.store.getItem('zoom_access_token');
    }

    getMicrosoftAccessToken() {
        return this.store.getItem('ms_access_token');
    }

    getMicrosoftEventsAccessToken() {
        return this.store.getItem('ms_event_access_token');
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }

        this.errorData = {
            errorTitle: 'Oops! Request for document failed',
            errorDesc: 'Something bad happened. Please try again later.'
        };
        return throwError(this.errorData);
    }
}
