<router-outlet></router-outlet>

<!--loading spinner-->
<!--<div class="loading-spinner" *ngIf="moduleLoading">-->
<!--    <div class="spinner-border text-primary" role="status">-->
<!--        <span class="sr-only">Loading...</span>-->
<!--    </div>-->
<!--</div>-->
<!--loading spinner-->








