import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ErrorMessages } from 'src/assets/system-files/Message';

interface DialogData {
  heading?: string; 
}

@Component({
  selector: 'app-add-update-social-field',
  templateUrl: './add-update-social-field.component.html',
  styleUrls: ['./add-update-social-field.component.scss']
})
export class AddUpdateSocialFieldComponent implements OnInit {
  errorMessage = ErrorMessages;
  form : FormGroup; 

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private _fb : FormBuilder,
  ) {
    this.createForm()
    console.log("modalService");
  }
  createForm(){
    this.form = this._fb.group({
      profile : ['',  Validators.required],
      profileImageUrl : ['', Validators.required],
      name : ['', Validators.required],
      url : ['', Validators.required]
    })
  }

  ngOnInit(): void {
  }

  readProfileImage(event) {
    console.log("event", event);
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0]; 
        this.form.get('profile').setValue(file);
        const reader = new FileReader();
        reader.onload = e => this.form.get('profileImageUrl').setValue(e.target.result as string);
        reader.readAsDataURL(file);
    }
  }

  checkValidation(value){
      return !this.validateInput(value)
  }

  validateUrl(value: string): boolean {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z0-9\\-])+\\.)+[a-zA-Z]{2,})' + // domain name
      '(\\:\\d{2,5})?' + // port (optional)
      '(\\/[-a-zA-Z0-9@:%._\\+~#=]*)*' + // path (optional)
      '(\\?[;&a-zA-Z0-9@:%_\\+.~#?&=\\-]*)?' + // query string (optional)
      '(\\#[-a-zA-Z0-9_]*)?$' // fragment identifier (optional)
    );

    return urlPattern.test(value);
  }


  validateInput(value: string): boolean {
    return this.validateUrl(value);
  }

}
