<div class="app-footer">
	<div class="row align-items-center">
		<div class="col-md-6 text-center text-sm-center text-md-left">
			<p class="m-0">&copy; {{year}} Qbitt | {{version}}</p>
		</div>
		<div class="col-md-6 text-md-right text-center text-sm-center">
			<p class="m-0">All Rights Reserved</p>
		</div>
	</div>
</div>
